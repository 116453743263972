@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&family=Share+Tech+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Raleway:wght@600&family=Share+Tech+Mono&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
  html {
    font-family: 'Raleway', sans-serif;
  }
}